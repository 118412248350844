<template>
  <div :class="mainClass">
    <div class="main">
      <div
        class="header row px-8 flex justify-between w-full !text-[16px]"
        :class="clazzName"
      >
        <div class="w-1/3">
          <span class="mobile">EWH.</span>
          <span class="desktop">Ernst-Wilhelm Händler.</span>
        </div>
        <div class="w-1/3 px-8">
          <div v-if="currentUser" class="w-full">
            <a
              @click="gotoAdmin"
              v-if="currentUser.username === 'admin'"
              class="cursor-pointer bg-black text-white px-4 py-2 rounded-full"
              >{{
                currentUser.username.charAt(0).toUpperCase() +
                  currentUser.username.slice(1)
              }}
              >>></a
            >

            <span v-if="currentUser.username !== 'admin'" class="label"
              >Das Archiv -
              {{
                currentUser.username.charAt(0).toUpperCase() +
                  currentUser.username.slice(1)
              }}.</span
            >
          </div>
          <span v-else class="label">Das Archiv.</span>
        </div>
        <div class="w-1/3 flex justify-end">
          <div @click="goToList">
            <RoundButton color="orange" icon="close" text="Schließen" />
          </div>
        </div>
      </div>
      <div class="subHeader row first px-8 flex h-full !text-[16px]">
        <div class="tags w-1/3 h-full flex items-center">
          <p class="label"><span>Thematik:</span></p>
          <div class="flex">
            <div v-for="(tag, i) in articleTags" :key="tag">
              <BadgeButton
                :tid="tag"
                color="trans"
                active="true"
                type="short"
              />
              <span v-if="i < article.tags.length - 1">,</span>
            </div>
          </div>
        </div>
        <div class="stroke double px-8 w-1/3 h-full flex items-center">
          <p class="label"><span>Datum: </span>{{ dateString }}</p>
        </div>
        <div class="stroke double px-8 h-full flex items-center">
          <RatingBar
            :rating="article.rating"
            :size="40"
            :article-hash="article.hash"
            :interactive="currentUser.roles.includes('ROLE_ADMIN')"
          />
        </div>
      </div>

      <div
        v-if="
          article.type !== 'video' &&
            article.type !== 'audio' &&
            article.type !== 'image'
        "
        class="row subHeader flex h-full px-8"
      >
        <div class="w-1/3 flex items-center h-full">
          <RoundButton
            :disabled="!showNextList"
            class="mr-2"
            @onClick="nextList"
            color="yellow"
            icon="listdown"
            text="Zeige nächste Seite"
          />
          <RoundButton
            :disabled="!showPrevList"
            class="mr-2"
            @onClick="prevList"
            color="yellow"
            icon="listup"
            text="Zeige vorherige Seite"
          />
          <RoundButton
            :disabled="!showPrevList"
            class="mr-2"
            @onClick="goToSlide(0)"
            color="yellow"
            icon="listfirst"
            text="Zeige erste Seite"
          />
        </div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <p class="label">
            <span>Scan</span> {{ currentList }}/{{ totalSlides }}
          </p>
        </div>
        <div class="stroke miscButtons w-1/3 h-full flex items-center px-8">
          <RoundButton
            class="mr-2"
            color="green"
            icon="down"
            @onClick="download"
            text="Download"
          />
          <RoundButton
            class="mr-2"
            v-if="hasLink"
            color="blue"
            icon="link"
            @onClick="gotoLink"
            text="Link to EWH"
          />
        </div>
      </div>

      <div
        v-if="article.type === 'image'"
        class="row subHeader flex h-full px-8"
      >
        <div class="w-1/3 flex items-center h-full"></div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <p class="label"><span>Bild</span> 1/1</p>
        </div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <RoundButton
            class="mr-2"
            color="green"
            icon="down"
            @onClick="download"
            text="Download"
          />
          <RoundButton
            class="mr-2"
            v-if="hasLink"
            color="blue"
            icon="link"
            @onClick="gotoLink"
            text="Link to EWH"
          />
        </div>
      </div>

      <div
        v-if="article.type === 'video'"
        class="row subHeader flex h-full px-8"
      >
        <div class="w-1/3 flex items-center h-full">
          <RoundButton
            class="mr-2"
            @onClick="playVideo"
            color="yellow"
            icon="play"
            text="Spiele Video"
          />
          <RoundButton
            class="mr-2"
            @onClick="pauseVideo"
            color="yellow"
            icon="pause"
            text="Pausiere Video"
          />
        </div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <p class="label"><span>Video</span> 1/1</p>
        </div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <RoundButton
            class="mr-2"
            color="green"
            icon="down"
            @onClick="download"
            text="Download"
          />
          <RoundButton
            class="mr-2"
            v-if="hasLink"
            color="blue"
            icon="link"
            @onClick="gotoLink"
            text="Link to EWH"
          />
        </div>
      </div>
      <div
        v-if="article.type === 'audio'"
        class="row subHeader flex h-full px-8"
      >
        <div class="w-1/3 flex items-center h-full">
          <RoundButton
            class="mr-2"
            @onClick="playAudio"
            color="yellow"
            icon="play"
            text="Spiele Audio"
          />
          <RoundButton
            class="mr-2"
            @onClick="pauseAudio"
            color="yellow"
            icon="pause"
            text="Pausiere Audio"
          />
        </div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <p class="label"><span>Audio</span> 1/1</p>
        </div>
        <div class="stroke w-1/3 h-full flex items-center px-8">
          <RoundButton
            class="mr-2"
            color="green"
            icon="down"
            @onClick="download"
            text="Download"
          />
          <RoundButton
            class="mr-2"
            v-if="hasLink"
            color="blue"
            icon="link"
            @onClick="gotoLink"
            text="Link to EWH"
          />
        </div>
      </div>

      <div
        v-if="
          article.type !== 'video' &&
            article.type !== 'audio' &&
            article.type !== 'image'
        "
        class="detailsWrapper"
        v-scroll:throttle="{ fn: elementInViewport, throttle: 500 }"
      >
        <ImageList
          ref="imglist"
          :resourcePath="resourcePath"
          :images="images"
          @onClick="showZoom"
        />

        <!--ImageCarousel ref="imgcar" :resourcePath="resourcePath" :images="images" @onClick="showZoom"/-->

        <div class="mobileFooter">
          <div>
            <RoundButton
              class="zoomButton"
              @onClick="showZoom"
              color="yellow"
              icon="zoom"
              text="Zoomansicht öffnen"
            />
          </div>
          <div class="stroke">
            <RoundButton color="green" icon="down" @onClick="download" />
          </div>
        </div>
      </div>
      <div
        v-if="article.type === 'image'"
        class="detailsWrapper"
        v-scroll:throttle="{ fn: elementInViewport, throttle: 500 }"
      >
        <ImageList
          ref="imglist"
          :resourcePath="resourcePath"
          :images="images"
          @onClick="showZoom"
        />

        <div class="mapWrapper" v-if="hasGeodata">
          <div id="mapdiv" class="h-72"></div>
        </div>
        <div v-else>
          <p class="text-center">Keine Geo-Koordinaten verfügbar</p>
        </div>
      </div>
      <div v-if="article.type === 'video'" class="mb-16">
        <video
          id="videoPlayer"
          controls
          class="w-2/3 mx-auto pt-8 max-w-5xl"
          ref="video_player"
        >
          <source :src="mediaPath" type="video/mp4" />
        </video>
      </div>
      <div v-if="article.type === 'audio'" class="mb-16">
        <audio
          controls
          class="w-2/3 mx-auto mt-16 max-w-7xl"
          ref="audio_player"
        >
          <source :src="mediaPath" type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <div
        v-if="
          currentUser.roles.includes('ROLE_ADMIN') ||
            (article.notes && article.notes !== '')
        "
        class="notes flex"
      >
        <div class="w-1/3 h-full">
          <p class="px-8 pt-8 mb-8">Informationen</p>
        </div>
        <div class="stroke w-2/3">
          <div class="px-8 pt-8 mb-8">
            <div v-if="currentUser.roles.includes('ROLE_ADMIN')">
              <textarea
                v-model="articleNotes"
                rows="5"
                class="w-full"
              ></textarea>
              <button @click="onSave">Speichern</button>
            </div>
            <p v-else v-html="articleNotesHtml"></p>
          </div>
        </div>
      </div>
    </div>
    <ImageZoom
      v-if="zoomImg"
      :article="article"
      :path="resourcePath"
      :page="zoomImg"
      :img="zoomImg"
      @onClose="hideZoom"
    />
  </div>
</template>

<script>
const SERVER_URL = process.env.VUE_APP_SERVER_URL
const S3_URL = process.env.VUE_APP_AWS_URL
const CF_AUDIO_URL = process.env.VUE_APP_AWS_CLOUDFRONT_AUDIO_URL
const CF_VIDEO_URL = process.env.VUE_APP_AWS_CLOUDFRONT_VIDEO_URL
//const S3_URL = 'https://ewh-archive-dev.s3.eu-central-1.amazonaws.com/';
//const S3_URL = 'https://ewh-archive-bucket.s3.eu-central-1.amazonaws.com/';
import ArticleService from "@/services/article"
//import TagService from "@/services/tag";
//import ImageCarousel from "@/components/ImageCarousel";
import ImageList from "@/components/ImageList"
import ImageZoom from "@/components/ImageZoom"
import RoundButton from "@/components/RoundButton.vue"
import BadgeButton from "@/components/BadgeButton.vue"
import RatingBar from "@/components/RatingBar.vue"

import { bus } from "@/main"

import { createInstance } from "vuex-pagination"

//import tags from "@/assets/tags.js";

export default {
  name: "detailsView",
  components: {
    ImageList,
    ImageZoom,
    RoundButton,
    BadgeButton,
    RatingBar,
  },
  props: {
    hash: {
      type: String,
    },
  },
  data() {
    return {
      article: {},
      articleNotes: "",
      selectedTags: [],
      tagInfo: "Info",
      tagInfoVisible: false,
      carouselIndex: 0,
      listIndex: 0,
      zoomImg: null,
      currentScroll: null,
      ignoreScroll: false,
      scrollSpeed: 300,
      scrollTimeout: 800,
      hasScrolled: false,
      mapVisible: false,
    }
  },
  computed: {
    articleNotesHtml() {
      return this.articleNotes.replace(/\n/g, "<br>")
      // article.notes.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    mediaPath() {
      if (this.article.type === "audio") {
        return `${CF_AUDIO_URL}${this.article.year || "XXXX"}/${
          this.article.hash
        }/${this.article.name}.mp3`
      } else if (this.article.type === "video") {
        return `${CF_VIDEO_URL}${this.article.year || "XXXX"}/${
          this.article.hash
        }/${this.article.name}.mp4`
      } else {
        return `${S3_URL}${this.article.year || "XXXX"}/${this.article.hash}/`
      }
    },
    imagePath() {
      return `${S3_URL}${this.article.year || "XXXX"}/${this.article.hash}/${
        this.article.name
      }.jpg`
    },
    hasLink() {
      return (
        typeof this.article.link !== "undefined" && this.article.link !== ""
      )
    },
    hasGeodata() {
      return !(
        //this.article.type === 'image' &&
        (this.article.lat == null && this.article.lon == null)
      )
    },
    resourcePath() {
      return `${S3_URL}${this.article.year || "XXXX"}/${this.article.hash}/`
    },
    dateString() {
      const parts = []
      if (this.article.day !== null) parts.push(this.article.day)
      if (this.article.month !== null) parts.push(this.article.month)
      parts.push(this.article.year || "XXXX")
      return parts.join(".")
    },
    slides() {
      if (this.article && this.article.pages > 0) {
        let slds = []
        for (var i = 0; i < this.article.pages; i++) {
          const ii = `00${i}`.slice(-3)
          slds.push(`${this.resourcePath}${this.article.name}-${ii}.jpg`)
        }
        return slds
      } else if (this.article && this.article.type === "image") {
        return [`${this.resourcePath}${this.article.name}.jpg`]
      }
      return []
    },
    images() {
      return this.slides
    },
    currentSlide() {
      if (!this.images || !this.$refs.imgcar) return 1
      return this.$refs.imgcar.currentSlide
    },
    currentList() {
      if (!this.images) return 1
      return this.listIndex + 1
    },
    totalSlides() {
      if (!this.images) return 0
      return this.images.length
    },
    showPrev() {
      if (!this.images || !this.$refs.imgcar) return false
      return this.$refs.imgcar.currentSlide > 1
    },
    showNext() {
      if (!this.images || !this.$refs.imgcar) return false
      return this.$refs.imgcar.currentSlide < this.images.length
    },
    showPrevList() {
      if (!this.images || this.ignoreScroll) return false
      return this.listIndex > 0
    },
    showNextList() {
      if (!this.images || this.ignoreScroll) return false
      return this.listIndex < this.images.length - 1
    },
    mainClass() {
      let clazz = "details"
      if (this.zoomImg) {
        clazz = clazz + " zoom"
      }
      return clazz
    },
    tags: createInstance("tags", {
      page: 1,
      pageFrom: 1,
      pageSize: 100,
    }),
    sortedTags() {
      if (typeof this.tags !== "undefined" && this.tags.items) {
        const st = this.tags.items.slice(0).sort((a, b) => {
          const nameA = a.key.toUpperCase() // ignore upper and lowercase
          const nameB = b.key.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
        return st
      }
      return []
    },
    articleTags() {
      if (typeof this.tags !== "undefined" && this.tags.items) {
        return this.selectedTags
          .filter(t => {
            const tmp = t.toUpperCase()
            return (
              !["PRI", "LIT", "BUS", "AUDIO", "VIDEO"].includes(tmp) &&
              !["IPHONE5", "IPHONE6", "IPHONE12PROMAX", "IPHONEXSMAX"].includes(
                tmp
              ) &&
              !tmp.startsWith("N_")
            )
          })
          .map(t => t.toUpperCase())
      }
      return []
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    clazzName() {
      const un = this.currentUser.username
      if (un === "privat") {
        return "bg_purple"
      } else if (un === "business") {
        return "bg_green"
      } else {
        return "bg_orange"
      }
    },
  },

  mounted() {
    ArticleService.getArticle(this.hash).then(
      response => {
        this.article = response.data
        this.selectedTags = this.article.tags

        if (this.article.type === "image" && this.hasGeodata) {
          this.showMap()
        }
        this.articleNotes = this.article.notes
      },
      error => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString()
      }
    )
  },
  beforeDestroy() {
    // removing eventBus listener
    bus.$off("article-rated")
  },
  methods: {
    elementInViewport() {
      if (this.ignoreScroll) {
        return false
      }
      const els = [...document.getElementsByClassName("imgWrapper")]
      let newListIndex = 0
      console.log("+++++++++++++++++")

      const offset = 225

      let done = false
      let lastVisible = false

      els.forEach(myElement => {
        if (done) {
          return false
        }

        var myElementHeight = myElement.offsetHeight
        //var myElementWidth = myElement.offsetWidth;
        var bounding = myElement.getBoundingClientRect()

        const hw =
          (window.innerHeight || document.documentElement.clientHeight) +
          myElementHeight
        //debugger;

        let isVisible = false

        if (
          bounding.top - offset >= -myElementHeight &&
          bounding.bottom <= hw
        ) {
          isVisible = true
          lastVisible = true
        }

        if (isVisible) {
          newListIndex = parseInt(myElement.id.replace("img_", ""))
          done = true
          if (lastVisible) {
            done = true
          }
        }
      })
      this.listIndex = newListIndex
    },
    onSave() {
      ArticleService.commentArticle(this.article.hash, this.articleNotes).then(
        response => {
          alert("Informationen wurden aktualisiert.")
          //bus.$emit("article-rated", this.articleHash, this.intRating)
        },
        error => {
          alert("Fehler!")
        }
      )
    },
    onDelete() {
      if (!confirm("Eintrag wird gelöscht, sicher?")) {
        return false
      }
      ArticleService.deleteArticle(this.hash).then(
        response => {
          if (response.status === 200) {
            this.$router.push("/archive")
          }
        },
        error => {
          this.content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        }
      )
    },
    toggleTag(tag) {
      if (this.selectedTags.includes(tag)) {
        this.selectedTags.splice(this.selectedTags.indexOf(tag), 1)
      } else {
        this.selectedTags.push(tag)
      }
      //this.$refs.editTags.innerText = JSON.stringify(this.selectedTags);
    },
    resetTags() {
      this.selectedTags = []
      this.tagInfoVisible = false
      //this.$refs.editTags.innerText = JSON.stringify(this.selectedTags);
    },
    goBack() {
      this.$router.back()
    },
    goToList() {
      this.$router.push("/archive")
    },
    gotoLink() {
      window.open(this.article.link, "_blank")
      return false
    },
    gotoAdmin() {
      this.$router.push(`/admin/articles/${this.article._id}`)
    },

    nextSlide() {
      debugger
      //this.$refs.imgcar.next();
    },
    prevSlide() {
      //this.$refs.imgcar.prev();
    },
    playAudio() {
      this.$refs.audio_player.play()
    },
    pauseAudio() {
      this.$refs.audio_player.pause()
    },
    playVideo() {
      this.$refs.video_player.play()
    },
    pauseVideo() {
      this.$refs.video_player.pause()
    },
    scrollTo(nextEl) {
      this.ignoreScroll = true
      const el = document.getElementById(`img_${nextEl}`)
      const options = {
        container: ".detailsWrapper",
        easing: "ease-in",
        force: true,
        cancelable: true,
        onDone: () => {
          this.listIndex = nextEl
          setTimeout(() => {
            this.ignoreScroll = false
          }, 300)
        },
      }
      this.$scrollTo(el, this.scrollSpeed, options)
    },
    nextList() {
      this.scrollTo(this.listIndex + 1)
    },
    prevList() {
      this.scrollTo(this.listIndex - 1)
    },
    goToSlide(num) {
      this.scrollTo(num)
    },
    // eslint-disable-next-line
    hideZoom() {
      this.zoomImg = null
    },
    showZoom(index) {
      this.zoomImg = parseInt(index) + 1
    },
    download() {
      let resourcePath
      if (this.article.type === "audio") {
        resourcePath = `${this.resourcePath}${this.article.name}.mp3`
      } else if (this.article.type === "video") {
        resourcePath = `${this.resourcePath}${this.article.name}.mp4`
      } else if (this.article.type === "image") {
        resourcePath = `${this.resourcePath}${this.article.name}.${this.article.ext}`
      } else {
        resourcePath = `${this.resourcePath}${this.article.name}.pdf`
      }
      window.open(resourcePath, "_blank")
    },
    showMap() {
      if (!this.mapVisible) {
        this.mapVisible = true

        this.$nextTick(async () => {
          //this.initMap();

          const position = {
            lat: parseFloat(this.article.lat),
            lng: parseFloat(this.article.lon),
          }

          const { Map } = await google.maps.importLibrary("maps")
          const { AdvancedMarkerElement } = await google.maps.importLibrary(
            "marker"
          )

          const map = new Map(document.getElementById("mapdiv"), {
            center: position,
            zoom: 12,
            mapId: "DEMO_MAP_ID",
          })

          //The marker, positioned at Uluru
          const marker = new AdvancedMarkerElement({
            map: map,
            position: position,
          })
        })
      }
    },
  },
}
</script>
<style lang="scss">
.mapWrapper {
  position: relative;
  max-width: 420px;
  margin: 0 auto 9px;

  button {
    position: absolute;
    top: 9px;
    right: 9px;
  }
}

@media (min-width: 1281px) {
  .mapWrapper {
    max-width: 33.3%;
  }
}

.bg_purple {
  background: #8a73be !important;
}
.bg_green {
  background: #418f5f !important;
}

.notes {
  background: $grey;
  border-top: 1px solid black;
}
.details {
  position: relative;
  background: $grey_light;
  width: 100%;
  height: 100%;
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../assets/bg.jpg);
    background-position: center;
    background-size: cover;
  }
  .header {
    background: $orange_light;
    height: $lineHeightL;
    font-size: 16px;

    span.mobile {
      display: none;
    }

    @media (max-width: 920px) {
      span.mobile {
        display: block;
      }
      span.desktop {
        display: none;
      }
    }
  }
  .subHeader {
    @extend .row;
    background: $grey_light;
    height: $lineHeightL;
    display: flex;

    .tags button {
      margin-right: 0;
      font-size: 16px;
    }
    .tags {
      .manage {
        cursor: pointer;
        padding: 4px;
        &:hover {
          background: $grey;
        }
      }
    }
  }
  .detailsWrapper {
    height: calc(100% - 225px);
    overflow: auto;
    background: $grey;
  }
  .main {
    width: 100%;
    height: 100%;
  }
  .label {
    padding-right: 6px;
    font-size: 16px;
  }

  .linkModal {
    display: flex;
    padding: 7px 30px;
    background: whitesmoke;
    position: absolute;
    top: 225px;
    height: 50px;
    right: 0;
    width: calc(50% - 80px);
    overflow: auto;
  }

  .editModal {
    padding: 30px;
    background: wheat;
    position: absolute;
    top: 275px;
    right: 0;
    width: calc(50% - 80px);
    bottom: 0;
    overflow: auto;
  }

  .tagModal {
    padding: 30px;
    background: $grey;
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    height: 460px;
    z-index: 500;
    border-bottom: 1px solid black;
    .tags {
      width: 100%;
      height: 420px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      overflow: scroll;
      div {
        display: block;
        flex: 0 0 auto;
        width: 33.3%;
        padding: 5px 10px;
        &:hover,
        &.active {
          background: $yellow;
          cursor: pointer;
        }
        &:hover {
          background: $orange;
        }
      }
    }
  }
  .mobileDownload {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  &.zoom {
    .mobileDownload {
      display: none;
    }
  }
  .mobileFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    height: 75px;
    width: 100%;
    background: $grey_light;
    border-top: 1px solid black;
    div {
      position: relative;
      width: 50%;
      margin-left: 20px;
      margin-top: 10px;
      &.stroke {
        &:before {
          position: absolute;
          content: " ";
          border-left: 1px solid black;
          height: 26px;
          top: -10px;
          left: -20px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .detailsWrapper {
      height: calc(100% - 300px);
    }
    .mobileFooter {
      display: flex;
    }

    .mobileDownload {
      display: none;
      z-index: 1;
    }
    .miscButtons {
      display: none !important;
    }
    .subHeader {
      //padding-left: 20px;
      padding-right: 20px;
      .right {
        display: none;
      }
      .label span {
        display: none;
      }
      &.first > div {
        width: 50% !important;
      }
    }
  }

  &.edit {
    .imgWrapper {
      margin: 0 0 9px;
    }
  }
}
</style>
