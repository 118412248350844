<template>
  <div class="relative" :class="clazzName">
    <div ref="bg" class="bg"></div>
    <HeaderBar :matches="matches" />
    <SearchBar
      class="searchbar"
      :class="searchBarVisible ? 'visible' : 'hid'"
      :searchObject="searchObject"
    />
    <SearchInfoBar
      v-if="searchActive"
      @onResetSearch="resetSearch"
      :searchObject="searchObject"
      :matches="matches"
    />
    <div ref="wrapper" class="wrapper initial">
      <div ref="listheader" v-show="!searchActive" class="listHeader">
        <div
          class="flex cursor-pointer text-[16px] items-center"
          @click="toggleSearch"
        >
          Suche
          <div class="icon angle pl-12"></div>
          <span v-if="matches !== false" class="matchesString"
            >: {{ matches }} Treffer</span
          >
        </div>
        <div
          v-if="matches !== false"
          @onClick="resetSearch"
          icon="close"
          text="Suche zurücksetzen"
        ></div>
      </div>

      <ListComponent
        ref="list"
        :class="searchBarVisible ? 'list blurred' : 'list'"
        :articles="articles.items"
        @onLoadMore="loadMore"
        :layout="listLayout"
      />
      <div
        v-if="searchBarVisible"
        class="searchOverlay"
        @click="searchBarVisible = false"
      ></div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue"
import ListComponent from "@/components/ListComponent.vue"
import SearchBar from "@/components/SearchBar.vue"
import SearchInfoBar from "@/components/SearchInfoBar.vue"
//import ArticleService from "../services/article";

import { createInstance, resource } from "vuex-pagination"

//import Article from '@/models/article';

import { bus } from "@/main"

export default {
  name: "archive",
  components: {
    HeaderBar,
    ListComponent,
    SearchBar,
    SearchInfoBar,
  },
  data() {
    return {
      //adminMode: false,
      scrollPos: 0,
      currentPage: 1,
      searchBarVisible: false,
      searchObject: {
        k: "",
        t: [],
        dfm: null,
        dfy: null,
        dtm: null,
        dty: null,
      },
    }
  },
  activated() {
    this.$refs.wrapper.scrollTop = this.scrollPos
  },
  deactivated() {
    this.scrollPos = this.$refs.wrapper.scrollTop
  },
  computed: {
    articles: createInstance("articles", {
      page: 1,
      pageFrom: 1,
      pageSize: 25,
      args() {
        return {
          keywords: this.searchObject.k,
          tags: this.searchObject.t,
          dfm: this.searchObject.dfm,
          dfy: this.searchObject.dfy,
          dtm: this.searchObject.dtm,
          dty: this.searchObject.dty,
        }
      },
    }),
    tags: createInstance("tags", {
      page: 1,
      pageFrom: 1,
      pageSize: 100,
    }),
    matches() {
      return this.searchActive ? this.articles.total : false
    },
    clazzName() {
      let cn = ["archive"]
      if (this.searchActive) {
        cn.push("searchActive")
      }
      if (this.searchBarVisible) {
        cn.push("searchBarVisible")
      }

      return cn.join(" ")
    },
    searchActive() {
      return (
        (this.searchObject.k && this.searchObject.k !== "") ||
        (this.searchObject.t && this.searchObject.t.length > 0) ||
        this.searchObject.dfy ||
        this.searchObject.dty
      )
    },
    listLayout() {
      return this.$store.state.isAdmin ? "admin" : ""
    },
  },
  created() {},
  updated() {},
  mounted() {
    setTimeout(() => {
      this.$refs.wrapper.classList.remove("initial")
      this.$refs.bg.classList.add("faded")
    }, 4000)

    resource("tags").refresh()

    bus.$on("hideSearch", () => {
      this.searchBarVisible = false
    })
    bus.$on("refreshArticles", () => {
      resource("articles").refresh()
      console.log("Articles refreshed")
    })
    bus.$on("refreshTags", () => {
      resource("tags").refresh()
      console.log("Tags refreshed")
    })
    bus.$on("showSearch", () => {
      this.searchBarVisible = true
    })

    bus.$on("article-rated", (hash, rating) => {
      this.$store.dispatch("rateArticle", { hash, rating })
    })

    bus.$on("toggleAdmin", () => {
      if (localStorage.getItem("ewhAdmin")) {
        localStorage.removeItem("ewhAdmin")
      } else {
        localStorage.setItem("ewhAdmin", true)
      }

      //if (this.$store.state.isAdmin) {
      //  this.$store.dispatch('logoutAdmin');
      //} else {
      //  this.$store.dispatch('loginAdmin');
      //}
    })

    bus.$on("resetSearch", () => {
      this.searchObject = {
        k: "",
        t: [],
        dfm: null,
        dfy: null,
        dtm: null,
        dty: null,
      }
      this.scrollPos = 0
      this.$refs.wrapper.scrollTop = 0
      //bus.$emit('search', this.searchObject);
    })
    bus.$on("logOut", () => {
      //this.$refs.wrapper.classList.add('initial');
      //this.$refs.bg.classList.remove('faded');
      //this.$refs.listheader.classList.remove('noBorder');
      this.$store.dispatch("auth/logout")
      this.$router.go("/")
    })

    bus.$on("search", searchObject => {
      this.searchObject = searchObject
      this.$refs.wrapper.scrollTop = 0

      //resource("articles").refresh()

      //this.$store.dispatch("searchArticles", searchObject).then(
      //  response => {
      //    this.articles = response
      //    this.searchBarVisible = false
      //  },
      //  error => {
      //    debugger
      //    this.content =
      //      (error.response && error.response.data) ||
      //      error.message ||
      //      error.toString()
      //  }
      //)
    })
  },
  methods: {
    toggleSearch() {
      this.searchBarVisible = !this.searchBarVisible
      //this.searchInfoVisible = !this.searchInfoVisible;
    },
    logOut() {
      bus.$emit("logOut")
    },
    loadMore() {
      this.articles.pageTo++
    },
    resetSearch() {
      bus.$emit("resetSearch")
    },
  },
}
</script>
<style lang="scss">
@keyframes example {
  from {
    top: calc(100% - 75px);
  }
  to {
    top: $lineHeightL;
  }
}

.archive {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .wrapper {
    position: absolute;
    background: $grey;
    top: $lineHeightL;
    left: 0;
    right: 0;
    bottom: 0;
    //height: calc(100% - $lineHeightL);
    overflow: scroll;
    &.initial {
      animation-name: example;
      animation-duration: 1s;
      animation-delay: 3s;
      animation-fill-mode: both;
    }
  }
  .list.blurred {
    filter: blur(15px);
  }
  .listHeader {
    margin-top: 0;
    height: $lineHeightL;
    background: $grey-light;
    display: flex;
    padding: 0 30px;
    align-items: center;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    font-size: 20px;
    &.noBorder {
      border-top: none;
    }
  }
  .bg {
    position: absolute;
    top: $lineHeightL;
    left: 0;
    width: 100%;
    height: calc(100% - 150px);
    z-index: 0;
    background: $grey;
    background-image: url(../assets/bg.jpg);
    background-position: center;
    background-size: cover;
    //background-repeat: no-repeat;
    opacity: 1;
    &.faded {
      opacity: 0;
      transition: opacity 2s;
    }
    @media (max-width: 768px) {
      background-size: cover;
    }
  }

  &.searchActive {
    .wrapper {
      //height: calc(100vh - $lineHeightL * 2);
      top: $lineHeightL * 2;
    }
  }
}

.searchOverlay {
  position: fixed;
  width: 100vw;
  height: 100%;
  top: $lineHeightL;
  left: 0;
  z-index: 1;
  background: transparent;
  //filter: blur(4px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.icon {
  width: 18px;
  height: 18px;
}
</style>
